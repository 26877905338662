import * as React from "react"
import { Link } from "gatsby"
import scorchFireLogo from "../images/scorch-fire-logo.png"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useState } from "react"

interface Props {
  signedIn: boolean
  auth? : any
}

const Navbar: React.FC<Props> = props => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true) //navCollapsed state hook
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed) //Handles pressing nav bar toggle


  return (
    <div>
      {/* Nav Bar */}
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <Link to="/" className="navbar-brand smoothScroll">
            <img
              className="nav-logo"
              src={scorchFireLogo}
              alt="scorch fire logo"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed ? true : false}
            onClick={handleNavCollapse}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarNav"
          >
            {props.signedIn == false || props.auth == undefined ? (
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link smoothScroll col"
                    onClick={() => scrollTo("#about")}
                  >
                    About Us
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link smoothScroll col"
                    onClick={() => scrollTo("#services")}
                  >
                    Services
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link smoothScroll col"
                    onClick={() => scrollTo("#contact")}
                  >
                    Contact
                  </button>
                </li>
                <li className="nav-item">
                  <Link
                    to="/app/client"
                    className="btn btn-link nav-link smoothScroll col"
                  >
                    Log In
                  </Link>
                </li>
              </ul>
            ) : (
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <button
                      className="btn btn-link nav-link smoothScroll"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log("Signing Out")
                        props.auth?.signOut()
                      }}
                    >
                      Log out
                    </button>
                  </li>
                </ul>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
